import anime from "animejs/lib/anime.es.js";

// Select elements
const hamburger = document.querySelector(".hamburger");
const bar1 = document.querySelector(".bar1");
const bar2 = document.querySelector(".bar2");
const bar3 = document.querySelector(".bar3");
const navMenu = document.querySelector(".nav-menu");
const navLinks = document.querySelectorAll(".nav-menu ul li");

// Flag to track menu state
let menuOpen = false;

const blobPaths = [
  // Shape 1
  "M45.9,-78.7C58.7,-72.1,67.8,-58.3,72,-43.9C76.3,-29.6,75.6,-14.8,72.8,-1.6C70,11.6,65.1,23.1,59,34.3C52.9,45.4,45.8,56.1,35.8,61.6C25.7,67.1,12.9,67.3,-1.4,69.8C-15.7,72.2,-31.4,76.9,-44.5,73.2C-57.7,69.5,-68.2,57.5,-74.4,43.9C-80.5,30.3,-82.2,15.2,-80,1.3C-77.8,-12.6,-71.6,-25.2,-64.9,-37.7C-58.1,-50.2,-50.7,-62.6,-39.8,-70.3C-28.9,-78,-14.4,-81,1.1,-82.9C16.5,-84.7,33.1,-85.3,45.9,-78.7Z",
  // Shape 2
  "M35.9,-59.3C50.2,-53.9,68,-51.7,77.2,-42.4C86.4,-33,87.1,-16.5,83.9,-1.8C80.7,12.8,73.7,25.6,65.7,37.2C57.8,48.8,48.9,59,37.8,64.5C26.7,70,13.3,70.6,-0.4,71.4C-14.2,72.1,-28.5,73,-41.5,68.7C-54.4,64.3,-66.2,54.7,-74.3,42.3C-82.4,30,-86.9,15,-86.1,0.4C-85.4,-14.1,-79.4,-28.3,-70.4,-39C-61.3,-49.7,-49.2,-57,-37,-63.5C-24.8,-70.1,-12.4,-75.8,-0.8,-74.5C10.8,-73.1,21.6,-64.6,35.9,-59.3Z",
  // Shape 3
  "M35.5,-61.5C47.3,-54.7,59,-47.8,66.1,-37.5C73.2,-27.2,75.6,-13.6,74,-1C72.3,11.7,66.6,23.4,61.3,36.8C56,50.2,51.2,65.3,41.1,71.9C31,78.5,15.5,76.5,2,73C-11.4,69.5,-22.9,64.5,-35.1,59.1C-47.3,53.7,-60.2,48,-68.5,38.1C-76.7,28.2,-80.3,14.1,-81.9,-0.9C-83.5,-16,-83.2,-31.9,-75,-41.8C-66.7,-51.7,-50.5,-55.5,-36.7,-61.2C-22.8,-66.8,-11.4,-74.3,0.2,-74.7C11.9,-75.1,23.7,-68.4,35.5,-61.5Z",
];

// Get the blob path element
const blobPath = document.getElementById("blob-path");

// Create the animation
anime({
  targets: blobPath,
  d: [
    { value: blobPaths[0] },
    { value: blobPaths[1] },
    { value: blobPaths[0] },
    { value: blobPaths[2] },
    { value: blobPaths[0] },
    { value: blobPaths[1] },
    { value: blobPaths[0] }, // Loop back to the first shape
  ],
  duration: 2500,
  easing: "cubicBezier(.5, .05, .1, .3)",
  loop: true,
});

// Content data
const contentData = {
  Etusivu: `
    <div class="content-container">
      <h1 class="text-centered">Makoisaa masaatsia ja positiivisia kehokokemuksia</h1>
      <div class="buttons-container">
        <a href="https://varaa.timma.fi/hierojapepe" target="_blank" class="button"> AJANVARAUS </a>
        <a href="https://www.instagram.com/hierojapepe/" class="button"> INSTAGRAM </a>
      </div>
    </div>
  `,
  Pepesta: `
    <div class="text-container">
    <h1>Pepestä</h1>
        <div class="two-column-container">
            <div class="column">
                <p>
                    Olen kasarin puolella syntynyt millenniaalin arkkityyppi, jonka tulevaisuus on aina avoinna muutoksille ja mahdollisuuksille. Elämääni rikastuttavat koirat, köllöttely, mökkeily ja sienestys, esitystaiteet, sekä hyvä ruoka ja juoma hyvässä seurassa. Nautin rauhasta ja hiljaisuudesta sekä arjen rutiineista höystettynä satunnaisella suurkaupunkien sykkeellä ja seikkailuilla. 
                </br>
                </br>
                    Työskentelin vuosikymmenen luovana suunnittelijana muun muassa markkinoinnin, brändien ja opastesuunnitelmien parissa. Todettuani ettei toimistotyöskentely olekaan minua varten, käännyin kannoillani ja opiskelin itselleni koulutetun hierojan tutkinnon hyvämaineisessa Suomen urheiluhierojaopistossa. 
                </p>
                </div>
                <div class="column">
                <p>
                    Työni tavoitteena on tarjota kehoa ja mieltä rentouttavia sekä positiivista kehonkuvaa vahvistavia kokemuksia. Palvelussani yhdistyvät ammattitaitoinen hieronta sekä kehopositiivisuuden ja turvallisemman tilan periaatteet: kaikenlaiset kehot ansaitsevat hyväksyntää sekä hyvää hoitoa ilman pelkoa, häpeää tai ennakkoluuloja. Lihasjännitysten lisäksi pyrin lievittämään myös stressiä ja ahdistuneisuutta. Esteettinen ympäristö, tunnelmallinen valaistus sekä harkiten kuratoitu musiikki ovat yhtä tärkeä osa palvelukokonaisuutta kuin hierontakin. 
                </p>
                </div>
            </div>
        </div>
    </div>
  `,
  Hieronnasta: `
    <div class="text-container">
    <h1>Hieronnasta</h1>
        <div class="two-column-container">
            <div class="column">
                <p>
                Hieronnoissani yhdistelen klassisen ja urheiluhieronnan tekniikoita tarpeen mukaan. Teen
                myös raskausajan hierontaa ja purentalihashierontaa
                </br>
                </br>
                Kohdistan käsittelyn aina asiakkaan toivomalle alueelle, ja pyrin varmistamaan hieronnan
                aikana että hoito tuntuu kaikin puolin miellyttävältä. Otteeni ovat pehmeitä ja liu’utukset
                hitaita, jotta kudoksilla on aikaa tottua tuntemuksiin. Makoisan kivun rajoilla saa viipyillä,
                mutta epämiellyttäviä tuntemuksia ei tarvitse sietää. Hoidon aikana on ehdottoman
                sallittua pyytää keventämään tai voimistamaan otetta; tuntoaisti sekä mieltymykset ovat
                yksilöllisiä, ja haluan että saat juuri sinulle sopivaa hierontaa.
                </p>
            </div>
            <div class="column">
                <h2>
                    Minkä pituinen aika kannattaa varata?
                </h2>
                <p>
                    Valitse 30 minuutin aika, jos haluat kohdistaa hoidon rajatulle alueelle, kuten niska-
                    hartiaseudulle.
                    </br>
                    </br>
                    Valitse 45 tai 60 minuutin aika, jos haluat kunnon hieronnan joko selkään tai alaraajoihin.
                    </br>
                    </br>
                    Valitse 75, 90 tai 120 minuutin aika jos haluat että käsittelen sekä ylä- että alavartalon.
                </p>
            </div>
        </div>
        <div class="two-column-container">
            <div class="column">
                <h2>
                    Mihin hieronta auttaa?
                </h2>
                <p>
                    Hieronnalla voi lievittää kipua, alentaa hetkellisesti verenpainetta, helpottaa päänsärkyjä,
                    sekä aktivoida kehoa ja mieltä rauhoittavaa parasympaattista hermostoa. Uskon että lihasten ja nivelten huoltamisen lisäksi hieronnassa on paljon hyödyntämätöntä potentiaalia myös mielen hyvinvoinnin tukemiseen.
                </p>
            </div>
            <div class="column">
                <h2>
                    Mitä on purentalihashieronta?
                </h2>
                <p>
                    Purentalihashieronnassa käsitellään leuan, kasvojen ja pään alueen lihaksistoa hieromalla
                    poskia sekä suun sisäpuolelta että kasvojen päältä. Purentalihashieronnasta voivat hyötyä
                    erityisesti bruksismista (eli hampaiden narskuttelusta tai yhteen puremisesta) kärsivät
                    henkilöt. Bruksismi voi aiheuttaa esimerkiksi leuan alueen kipua, päänsärkyjä sekä
                    korvaoireita.
                </p>
            </div>
        </div>
        <h1>Mitä hierontatilanteessa tapahtuu?</h1>
        <div class="two-column-container">
            <div class="column">
                <h2>
                    1. Ennen hierontaa
                </h2>
                <p>
                    Tervetuloa! Saavuthan korkeintaan viisi minuuttia etuajassa, sillä erillistä odotustilaa ei
                    pienessä salongissa ole. Jos edellinen hieronta on vielä kesken tai asiakas on
                    pukeutumassa, ovat väliovi sekä ikkunan verhot suljettuina. Jos taas verhot ja väliovi ovat
                    auki, voit huoleti astua sisään. Jokaisen asiakkaan jälkeen on vähintään 15 minuutin tauko.
                    Hieronnan aikana ulko-ovi on aina lukittuna, eikä sisään pääse ilman että avaan oven
                    sisäpuolelta.
                    </br></br>
                    Kengät voi jättää kynnysmaton päälle ja ulkovaatteet naulakkoon. Tämän jälkeen pyydän
                    sinua istumaan sohvalle täyttämään uuden asiakkaan henkilö- ja terveystietolomakkeen,
                    jonka jälkeen kysyn tarkemmin miten voin olla avuksi. Käymme yhdessä läpi hoidon
                    tarpeen ja miten käytämme varaamasi ajan.
                </p>
            </div>
            <div class="column">
                <h2>
                    2. Hierontapöydälle asettuminen
                </h2>
                <p>
                    Kun meillä on yhteisymmärrys tulevasta hieronnasta, pyydän sinua riisumaan vaatetusta
                    käsiteltäviltä alueilta ja asettumaan hierontapöydälle. Jos haluat pitää vaatekappaleita
                    päälläsi, sekin on ok – silloin en käytä hieronnassa rasvaa, vaan painelen kämmenillä ja
                    sormenpäillä vaatteen läpi. Pöydällä maatessasi peittelen sinut lämpimästi viltein,
                    lakanoin ja pyyhkein, ja paljastan vain käsiteltävän alueen.
                    </br></br>
                    Hierontapöydällä maataan vatsallaan pää kasvotukeen ja käsivarret käsitukiin nojaten, tai
                    selin jolloin avaan pöydän sivuille levikkeet käsivarsia varten. Hieronnan voi tehdä myös
                    kylkimakuulla tai istuma-asennossa. Kysy erilaisista asennoista, jos vaikkapa
                    vaakatasossa makaaminen esimerkiksi kivun, vamman tai refluksitaudin takia ei sovi
                    kehollesi!
                    </br></br>
                    Käsitukia säädetään kunkin asiakkaan olkavarsien pituuteen sopivaksi. Hierontapöydän
                    kasvotuessa on kahdet saranat, joilla voi säätää sen kallistuskulmaa että korkeutta.
                    Korkeutta säätämällä saadaan annettua lisää tilaa rintamukselle, joka voi helpottaa
                    hengittämistä. Kerrothan jos sinun on hankala olla – asentoa voidaan säätää ja muuttaa
                    myös kesken hieronnan.
                    </br></br>
                    Hierontatilassani soi valitsemani rauhallinen, hidastempoinen musiikki. Soittolistoiltani
                    löytyy folk-poppia, neoklassista sekä ambientia.
                </p>
            </div>
        </div>
        <div class="two-column-container">
            <div class="column">
                <h2>
                    3. Hieronnan aikana
                </h2>
                <p>
                    Aloitan hieronnan pyyhkeen läpi painellen, jonka jälkeen jatkan hierontavoidetta tai öljyä
                    väliaineena käyttäen. Käyttämäni väliaineet ovat neutraaleja ja tuoksuttomia.
                    </br></br>
                    Hieronnan aikana saa olla hiljaa ja keskittyä hengittämiseen, hetkessä olemiseen sekä
                    kehon tuntemusten aistimiseen. Pyrin havainnoimaan tilannetta enkä yritä jututtaa, jos
                    haluat nauttia hiljaisuudessa. Tiedustelen korkeintaan kerran tai pari hieronnan
                    voimakkuudesta. Jos taas haluat mieluummin jutella, niin keskustelen toki mielelläni
                    aiheesta kuin aiheesta.
                    </br></br>
                    Kerrothan minulle mikäli hieronnan voimakkuus, asento tai hierontapöydän säädöt
                    aiheuttaa sinulle epämukavuutta. Tärkeintä on että sinulla on koko hieronnan ajan
                    turvallinen, mukava ja rentoutunut olo sekä helppo hengittää.
                </p>
            </div>
            <div class="column">
                <h2>
                    4. Hieronnan jälkeen
                </h2>
                <p>
                    Ilmaisen hieronnan päättymisen laskemalla käteni kehollesi ja kutsumalla sinua nimeltä.
                    Siirryn pesemään käsiä jonka aikana voit nousta ja pukeutua rauhassa. Hieronnan
                    päätyttyä on hyvä juoda hieman vettä jos janottaa, mutta nestettä ei tarvitse loppupäivän
                    aikana tankata normaalia enempää. Hieronnan jälkeen voi myös esimerkiksi saunoa,
                    harrastaa liikuntaa ja olla muutenkin ihan normaalisti; pyri kuitenkin ylläpitämään
                    hieronnalla saavutettua rentoutunut olotila.
                    </br></br>
                    Hieronnan jälkeen otan maksun: maksuvälineinä käyvät kortti- ja mobiilimaksu sekä
                    Epassi-, Edenred-, Smartum -sovellukset. MobilePay tai käteinen eivät valitettavasti käy
                    toistaiseksi. Lopuksi kirjaan henkilö- ja terveystietolomakkeeseen tehdyt toimenpiteet ja
                    havainnot, jotta seuraavalla kerralla muistan mitä alueita on käsitelty.
                    </br></br>
                    Kahden asiakkaan välissä on vähintään 15 minuutin tauko, jolla varmistetaan että
                    saapumisen ja lähdön hetket ovat myös rauhallisia.
                </p>
            </div>
        </div>
    </div>
  `,
  Hinnasto: `
    <div class="text-container">
        <h1>Hinnasto & Info</h1>
        <div class="two-column-container">
          <div class="column first-item">
            <h2>Hierontahinnasto</h2>
            <div class="pricing">
              <ul>
                <li>30 min 30 €</li>
                <li>45 min 45 €</li>
                <li>60 min 60 €</li>
                <li>75 min 75 €</li>
                <li>90 min 90 €</li>
                <li>120 min 120 €</li>
              </ul>
            </div>
          </div>
          <div class="column second-item">
            <h2>Lisäpalvelut</h2>
            <h3>Jalkapohjien, kämmenien tai kasvojen ja pään hieronta</h3>
            <p><span class="price-tag">15 min 15 €</span></p>
            <h3>Purentalihasten käsittely</h3>
            <p><span class="price-tag">15 min 15 €</span></p>
            <h2>Muut hieronnat</h2>
            <h3>Purentalihashieronta</h3>
            <p><span class="price-tag">45 min 55 €</span></p>
            <h3>Kaverihieronta</h3>
            <p><span class="price-tag">2 x 50 min 110 €</span></p>
            <p>
              Tarkemmat tiedot eri palveluista löydät <a href="https://varaa.timma.fi/hierojapepe">ajanvaraussivulta.</a>
              Salongissa ei ole erillistä odotustilaa, joten saavuthan mieluiten enintään viisi minuuttia ennen varaamaasi
              aikaa, kiitos.
            </p>
          </div>
          <div class="column third-item lahjakortti-ja-maksuvalineet">
            <p>
              Maksuvälineenä käy kortti, lähimaksu, Epassi, Edenred ja Smartum hyvinvointietuudet. MobilePay ja käteinen eivät
              toistaiseksi käy.
            </p>
            <a class="button" href="https://varaa.timma.fi/giftcard/hierojapepe" target="_blank">Osta lahjakortti</a>
          </div>
        </div>
        <div class="two-column-container">
            <div class="column">
                <h2>Tilan saavutettavuus</h2>
                <p>
                    Salonkini ei valitettavasti ole täysin esteetön.
                    Ulko-oven leveys on 82 cm.
                    Uloimman oven jälkeen on 65 cm syvä tuulikaappi, ja 80 cm leveä väliovi.
                    Portaita ei ole, mutta sisäänkäynnin yhteydessä on kadun puolella noin 5 cm ja tilan sisäpuolella
                    noin 12 cm askelma.
                    </br>
                    </br>
                    Hierontapöydän leveys on 55 cm ja pituus 197 cm (kasvotuki mukaan luettuna). Hierontapöydän
                    painoraja on 225 kg.
                    </br>
                    </br>
                    Tilassa on pieni vessa, joka on myös asiakkaiden käytössä. Vessa ei valitettavasti ole esteetön.
                    Vessan ovet ovat leveydeltään vain noin 51 cm. WC-istuimen kanssa samassa tilassa on lavuaari
                    ja käsisuihku.
                </p>
            </div>
            <div class="column">
                <p>
                    Tilan äänimaailman muodostavat kadulta kuuluva, varsin tasainen liikenteen ääni sekä kahdesta
                    kaiuttimesta kuuluva, rauhallinen musiikki.
                    </br>
                    </br>
                    Valaistus on himmeä ja epäsuora. Paksujen verhojen ollessa suljettuna on vuorokaudenajasta
                    riippumatta ns. “iltavalaistus”.
                    </br>
                    </br>
                    Mikäli tarvitset tukea esimerkiksi apuvälineiden, askelmien tai pöydälle asettumisen kanssa, autan
                    tietenkin! Sama pätee myös valaistuksen, äänimaailman, lämpötilan ynnä muun säätämisessä
                    mahdollisuuksien mukaan mieluisammaksi.
                    </br>
                    </br>
                    Jos tarvitset lisää tietoa tai haluat etukäteen esittää toiveen miten voin kohdata sinut ja olla avuksi
                    parhaalla mahdollisella tavalla, laita viestiä <a href="https://www.instagram.com/hierojapepe/">instagramissa</a> tai sähköpostitse pepe@hierojapepe.fi
                </p>
            </div>
        </div>
        <div class="map-container">
            <div id="map"></div>
        </div>
    </div>
  `,
  Kuvia: `
  <div class="image-container">
    <div class="gallery">
        <img src="./images/HierojaPepe_by_night.jpg" alt="Hieroja Pepe by Night" class="full-size" />
        <img src="./images/HierojaPepe_nayteikkuna.jpg" alt="Hieroja Pepe Window Display" class="three-quarter-image" />
        <img src="./images/HierojaPepe_salonki_sisalta1.jpg" alt="Hieroja Pepe Salon Interior" class="full-size" />
        <img src="./images/HierojaPepe_pride.jpg" alt="Hieroja Pepe Pride" class="three-quarter-image" />
        <img src="./images/IMG_0050.jpeg" alt="Hieroja Pepe Another Salon Interior" class="full-size" />
        <img src="./images/HierojaPepe_salonki.JPG" alt="Hieroja Pepe From Outside" class="full-size" />
        <img src="./images/HierojaPepe_paita.JPG" alt="Hieroja Pepe With A Pepe Shirt On" class="full-size" />
    </div>
  </div>
`,
};

// Select the content section
const contentSection = document.getElementById("content-section");

// Set content based on the current URL on page load
document.addEventListener("DOMContentLoaded", () => {
  const path = window.location.pathname.replace("/", "").toLowerCase();
  const contentKey = Object.keys(contentData).find((key) => key.toLowerCase() === path) || "Etusivu";
  loadContent(contentKey, false);
});

// Function to animate the hamburger menu
function animateHamburger() {
  if (!menuOpen) {
    // Open menu animation
    anime
      .timeline({ duration: 250, easing: "easeInOutQuad" })
      .add({ targets: bar1, translateY: 10, rotate: 45 }, 0)
      .add({ targets: bar2, opacity: 0 }, 0)
      .add({ targets: bar3, translateY: -10, rotate: -45 }, 0);
  } else {
    // Close menu animation
    anime
      .timeline({ duration: 250, easing: "easeInOutQuad" })
      .add({ targets: bar1, translateY: 0, rotate: 0 }, 0)
      .add({ targets: bar2, opacity: 1 }, 0)
      .add({ targets: bar3, translateY: 0, rotate: 0 }, 0);
  }
  menuOpen = !menuOpen;
}

// Function to toggle the navigation menu
function toggleMenu() {
  if (menuOpen) {
    navMenu.classList.add("active");
    document.body.classList.add("menu-open");

    // Animate menu items in
    navLinks.forEach((link, index) => {
      anime({
        targets: link,
        opacity: [0, 1],
        translateY: [15, 0],
        delay: index * 100,
        duration: 150,
        easing: "easeOutExpo",
      });
    });
  } else {
    // Animate menu items out
    navLinks.forEach((link, index) => {
      anime({
        targets: link,
        opacity: [1, 0],
        translateY: [0, 15],
        delay: index * 50,
        duration: 150,
        easing: "easeInExpo",
        complete: () => {
          if (index === navLinks.length - 1) {
            navMenu.classList.remove("active");
            document.body.classList.remove("menu-open");
          }
        },
      });
    });
  }
}

// Event listener for click on hamburger menu
hamburger.addEventListener("click", () => {
  animateHamburger();
  toggleMenu();
});

function loadGoogleMapsScript() {
  // Check if the Google Maps script is already loaded
  if (typeof google === "object" && typeof google.maps === "object") {
    // Initialize the map directly
    initMap();
  } else {
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyB_O9NBHOpjW5qhcV4fBwxCgRW1ItFzTsE&callback=initMap&loading=async&libraries=marker";
    document.head.appendChild(script);
  }
}

function initMap() {
  const location = { lat: 60.18475, lng: 24.9163 };
  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: 17,
    center: location,
    mapId: "85dd4dbeb3df9f09",
    disableDefaultUI: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: true,
    keyboardShortcuts: false,

    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
  });

  const customMarkerElement = document.createElement("div");
  customMarkerElement.className = "custom-marker";
  customMarkerElement.innerHTML = `
    <div class="marker-container">
      <img src="./images/P_FAV.svg" alt="hieroja-pepe-marker" />
    </div>
  `;

  // Create the AdvancedMarkerElement
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map: map,
    position: location,
    content: customMarkerElement,
  });
}

// Attach initMap to the global window object
window.initMap = initMap;

function loadContent(contentKey, addToHistory = true) {
  // Set the HTML content
  contentSection.innerHTML = contentData[contentKey] || "<p>Content not found.</p>";

  // Update the URL
  if (addToHistory) {
    const url = contentKey === "Etusivu" ? "/" : `/${contentKey.toLowerCase()}`;
    history.pushState({ contentKey }, null, url);
  }

  // Scroll to the top
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  // Close the menu if it's open
  if (menuOpen) {
    animateHamburger();
    toggleMenu();
  }

  // If the content is 'Hinnasto', initialize the map
  if (contentKey === "Hinnasto") {
    // Load the Google Maps script dynamically
    loadGoogleMapsScript();
  }
}

// Update event listener for navigation links
navLinks.forEach((link) => {
  link.addEventListener("click", (event) => {
    const anchor = link.querySelector("a");

    // Skip the external link
    if (anchor.classList.contains("external-link")) {
      return; // Allow normal behavior for external links
    }

    event.preventDefault(); // Prevent default link behavior for internal links
    const contentKey = anchor.getAttribute("data-content");
    loadContent(contentKey);
  });
});

// Handle browser navigation (back/forward buttons)
window.addEventListener("popstate", (event) => {
  const contentKey = event.state ? event.state.contentKey : "Etusivu";
  loadContent(contentKey, false);
});
