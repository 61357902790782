uniform vec3 uColorC;
uniform vec3 uColorD;
uniform float uStrength;

varying float vWobble;
varying float vInstanceStrength;
varying vec3 vColorC;
varying vec3 vColorD;
varying float vInstanceHover; // Add this line

void main() {
    float strength = vInstanceStrength * uStrength;
    float colorMix = smoothstep(-1.0, 1.0, vWobble);
    
    // Use vColorC and vColorD when hovered, otherwise use uColorC and uColorD
    vec3 colorCToUse = (vInstanceHover > 0.0) ? vColorC : uColorC;
    vec3 colorDToUse = (vInstanceHover > 0.0) ? vColorD : uColorD;

    csm_DiffuseColor.rgb = mix(colorCToUse, colorDToUse, colorMix);

    // Shiny tip
    csm_Roughness = 0.25 - colorMix;
}
