uniform float uTime;
uniform float uPositionFrequency;
uniform float uTimeFrequency;
uniform float uStrength;
uniform float uWarpPositionFrequency;
uniform float uWarpTimeFrequency;
uniform float uWarpStrength;

attribute vec4 tangent;
attribute float instanceStrength; 
attribute vec3 instanceColorC;
attribute vec3 instanceColorD;
attribute float instanceHover;

varying float vInstanceHover;
varying float vInstanceStrength;
varying float vWobble;
varying vec3 vColorC;
varying vec3 vColorD;

#include ../includes/simplexNoise4d.glsl;

float getWobble(vec3 position, float strength)
{
    vec3 warpedPosition = position;
    warpedPosition += simplexNoise4d(vec4(
        position * uWarpPositionFrequency,
        uTime * uWarpTimeFrequency
    )) * uWarpStrength;

    return simplexNoise4d(vec4(
        warpedPosition * uPositionFrequency, // XYZ
        uTime * uTimeFrequency               // W
    )) * uStrength * strength; // Multiply by instance-specific strength
}

void main()
{
    vec3 biTangent = cross(normal, tangent.xyz);

    // Neighbours positions
    float shift = 0.01;
    vec3 positionA = csm_Position + tangent.xyz * shift;
    vec3 positionB = csm_Position + biTangent * shift;

    // Wobble with instanceStrength
    float wobble = getWobble(csm_Position, instanceStrength);
    csm_Position += wobble * normal;
    positionA    += getWobble(positionA, instanceStrength) * normal;
    positionB    += getWobble(positionB, instanceStrength) * normal;

    // Compute normal
    if(abs(wobble) > 0.5) {
        // Only recompute normals if the wobble effect is strong
        vec3 toA = normalize(positionA - csm_Position);
        vec3 toB = normalize(positionB - csm_Position);
        csm_Normal = cross(toA, toB);
    }

    // Varyings
    vWobble = wobble / uStrength;
    vInstanceStrength = instanceStrength;
    vInstanceHover = instanceHover;
    vColorC = instanceColorC;
    vColorD = instanceColorD;
}
