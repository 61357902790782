import * as THREE from "three";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";
import Stats from "stats.js";
import GUI from "lil-gui";

import wobbleVertexShader from "./shaders/wobble/vertex.glsl";
import wobbleFragmentShader from "./shaders/wobble/fragment.glsl";

/**
 * Base
 */
// Debug
const debugObject = {};

// Create GUI instance
// const gui = new GUI();

// Set up the stats
const stats = new Stats();
// stats.showPanel(0); // 0: fps, 1: ms, 2: mb
// document.body.appendChild(stats.dom);

// Get the canvas element
const canvas = document.querySelector("canvas.webgl");

// Existing loading manager
const loadingManager = new THREE.LoadingManager(
  // When all items are loaded
  () => {
    const loaderElement = document.getElementById("loader");
    if (loaderElement) {
      loaderElement.style.display = "none";
    }
    tick(); // Start the animation loop after loading is complete
  },

  // During loading
  (itemUrl, itemsLoaded, itemsTotal) => {
    const progressElement = document.getElementById("progress");
    if (progressElement) {
      const progress = Math.round((itemsLoaded / itemsTotal) * 100);
      progressElement.innerText = `${progress}%`;
    }
  }
);

// Loaders
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath("./draco/");

const rgbeLoader = new RGBELoader(loadingManager);

const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

// Scene
const scene = new THREE.Scene();

scene.environmentIntensity = 2.5;
scene.backgroundBlurriness = 0.2;
scene.backgroundIntensity = 1.15;

// Environment Map
rgbeLoader.load("./environmentMaps/NightSkyHDRI007_1K-HDR.hdr", function (texture) {
  texture.mapping = THREE.EquirectangularReflectionMapping;

  // Set the scene background to the HDR texture
  scene.background = texture;

  blobMaterial.envMap = texture;
  blobMaterial.envMapIntensity = 1.2;
  blobMaterial.clearcoat = 0.25;
  blobMaterial.clearcoatRoughness = 0.5;
});

/**
 * Wobble
 */

debugObject.colorA = "#FF90F8";
debugObject.colorB = "#4A9879";
debugObject.colorC = "#346550";
debugObject.colorD = "#805F95";

const pepeUniforms = {
  uTime: new THREE.Uniform(0),
  uPositionFrequency: new THREE.Uniform(0.55),
  uTimeFrequency: new THREE.Uniform(0.25),
  uStrength: new THREE.Uniform(0.025),
  uWarpPositionFrequency: new THREE.Uniform(0.625),
  uWarpTimeFrequency: new THREE.Uniform(0.35),
  uWarpStrength: new THREE.Uniform(0.25),
};

const blobUniforms = {
  uTime: new THREE.Uniform(0),
  uPositionFrequency: new THREE.Uniform(0.5),
  uTimeFrequency: new THREE.Uniform(0.4),
  uStrength: new THREE.Uniform(0.5),
  uWarpPositionFrequency: new THREE.Uniform(0.25),
  uWarpTimeFrequency: new THREE.Uniform(0.25),
  uWarpStrength: new THREE.Uniform(1.75),
  uColorC: new THREE.Uniform(new THREE.Color(debugObject.colorC)),
  uColorD: new THREE.Uniform(new THREE.Color(debugObject.colorD)),
};

// Blob Uniforms Config
// const blobUniformsConfig = {
//   uPositionFrequency: blobUniforms.uPositionFrequency.value,
//   uTimeFrequency: blobUniforms.uTimeFrequency.value,
//   uStrength: blobUniforms.uStrength.value,
//   uWarpPositionFrequency: blobUniforms.uWarpPositionFrequency.value,
//   uWarpTimeFrequency: blobUniforms.uWarpTimeFrequency.value,
//   uWarpStrength: blobUniforms.uWarpStrength.value,
// };

// Blob Uniforms Folder
// const blobFolder = gui.addFolder("Blob Uniforms");
// blobFolder.add(blobUniformsConfig, "uPositionFrequency", 0, 2).onChange((value) => {
//   blobUniforms.uPositionFrequency.value = value;
// });

// blobFolder.add(blobUniformsConfig, "uTimeFrequency", 0, 2).onChange((value) => {
//   blobUniforms.uTimeFrequency.value = value;
// });

// blobFolder.add(blobUniformsConfig, "uStrength", 0, 2).onChange((value) => {
//   blobUniforms.uStrength.value = value;
// });

// blobFolder.add(blobUniformsConfig, "uWarpPositionFrequency", 0, 2).onChange((value) => {
//   blobUniforms.uWarpPositionFrequency.value = value;
// });

// blobFolder.add(blobUniformsConfig, "uWarpTimeFrequency", 0, 2).onChange((value) => {
//   blobUniforms.uWarpTimeFrequency.value = value;
// });

// blobFolder.add(blobUniformsConfig, "uWarpStrength", 0, 2).onChange((value) => {
//   blobUniforms.uWarpStrength.value = value;
// });

// blobFolder.open();

// Interaction Effect Parameters
const interactionEffect = {
  targetStrength: 0.5,
  duration: 0.75,
  colorC: "#784094",
  colorD: "#9d5dc7",
};

// // GUI for Interaction Effects
// const interactionFolder = gui.addFolder("Interaction Effects");
// interactionFolder.add(interactionEffect, "targetStrength", 0, 2).step(0.01);
// interactionFolder.add(interactionEffect, "duration", 0.1, 2).step(0.01);
// interactionFolder.addColor(interactionEffect, "colorC");
// interactionFolder.addColor(interactionEffect, "colorD");
// interactionFolder.open();

const blobMaterial = new CustomShaderMaterial({
  baseMaterial: THREE.MeshPhysicalMaterial,
  vertexShader: wobbleVertexShader,
  fragmentShader: wobbleFragmentShader,
  uniforms: blobUniforms,
  silent: true,
  metalness: 0,
  roughness: 0.5,
  transmission: 0,
  ior: 1.5,
  thickness: 1.5,
  wireframe: false,
});

const pepeMaterial = new CustomShaderMaterial({
  baseMaterial: THREE.MeshPhysicalMaterial,
  vertexShader: wobbleVertexShader,
  fragmentShader: wobbleFragmentShader,
  uniforms: pepeUniforms,
  silent: true,
  side: THREE.DoubleSide,
  wireframe: false,
});

pepeMaterial.emissive = new THREE.Color(debugObject.colorA);
pepeMaterial.emissiveIntensity = 3.25;

/**
 * Models
 */

let pepe;

gltfLoader.load("./models/pepe-02.glb", (gltf) => {
  pepe = gltf.scene.children[0];

  // Modify the pepe (e.g., scale, position)
  pepe.scale.set(4, 4, 4);
  pepe.position.set(0, 3, 0);
  pepe.rotation.x = Math.PI / 2;
  pepe.material = pepeMaterial;

  scene.add(pepe);
});

// Load multiple blob models
const blobFiles = ["./models/blob_01.glb", "./models/blob_02.glb", "./models/blob_03.glb", "./models/blob_04.glb"];

const isMobile = window.innerWidth <= 768;

const instanceCountPerModel = isMobile ? 4 : 12;
const matrix = new THREE.Matrix4();
const positionVector = new THREE.Vector3();
const rotationVector = new THREE.Euler();
const scaleVector = new THREE.Vector3();

// Define the exclusion zone
const exclusionZone = {
  x: 9.49,
  y: 4.06,
  z: 0.8,
  radius: 8, // Define the radius around the exclusion zone
};

// Function to check if a position is within the exclusion zone
function isWithinExclusionZone(pos) {
  const dx = pos.x - exclusionZone.x;
  const dy = pos.y - exclusionZone.y;
  const dz = pos.z - exclusionZone.z;
  const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
  return distance < exclusionZone.radius;
}

// Function to generate random positions with exclusion zone check
function generateRandomPositions(count, isMobile) {
  const positions = [];

  // Set different ranges for mobile vs desktop
  const xRange = isMobile ? [-6, 6] : [-25, 25]; // Narrower x-range for mobile
  const yStart = isMobile ? 25 : 10; // Start higher for mobile to spread vertically
  const yOffset = isMobile ? 6 : 2; // Larger y-spacing for mobile
  const zRange = [-10, 10]; // Z-axis range (same for both)

  while (positions.length < count) {
    const x = Math.random() * (xRange[1] - xRange[0]) + xRange[0];

    // Ensure x-value avoids the middle and check for exclusion zone
    if (x < -5 || x > 5) {
      const y = yStart - positions.length * yOffset; // Descending y-values
      const z = Math.random() * (zRange[1] - zRange[0]) + zRange[0];

      const position = { x, y, z };

      // Ensure the position is outside the exclusion zone
      if (!isWithinExclusionZone(position)) {
        positions.push(position);
      }
    }
  }
  return positions;
}

const totalInstances = instanceCountPerModel * blobFiles.length;
const basePositions = generateRandomPositions(totalInstances, isMobile);

const instancedBlobs = [];

/**
 * Load and Instantiate Blobs
 */
blobFiles.forEach((blobFile, modelIndex) => {
  gltfLoader.load(blobFile, (gltf) => {
    const blob = gltf.scene.children[0];
    const instancedMesh = new THREE.InstancedMesh(blob.geometry, blobMaterial, instanceCountPerModel);

    // Create and initialize instance attributes
    const instanceStrengthArray = new Float32Array(instanceCountPerModel);
    const instanceHoverArray = new Float32Array(instanceCountPerModel);
    const instanceColorCArray = new Float32Array(instanceCountPerModel * 3); // 3 for RGB
    const instanceColorDArray = new Float32Array(instanceCountPerModel * 3); // 3 for RGB

    const colorC = new THREE.Color(debugObject.colorC);
    const colorD = new THREE.Color(debugObject.colorD);

    for (let i = 0; i < instanceCountPerModel; i++) {
      instanceStrengthArray[i] = 0.2; // Default value for uStrength
      instanceHoverArray[i] = 0.0; // Default hover state

      instanceColorCArray.set([colorC.r, colorC.g, colorC.b], i * 3);
      instanceColorDArray.set([colorD.r, colorD.g, colorD.b], i * 3);

      const position = basePositions[modelIndex * instanceCountPerModel + i];

      // Random rotation between 0 and 2π
      rotationVector.set(Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2);

      // Uniform scale within the range
      const minScale = 2.25;
      const maxScale = 2.75;
      const scale = minScale + Math.random() * (maxScale - minScale);

      // Set position, rotation, and scale
      positionVector.set(position.x, position.y, position.z);
      scaleVector.set(scale, scale, scale);

      matrix.compose(positionVector, new THREE.Quaternion().setFromEuler(rotationVector), scaleVector);
      instancedMesh.setMatrixAt(i, matrix);
    }

    instancedMesh.instanceMatrix.needsUpdate = true;

    instancedMesh.geometry.setAttribute("instanceStrength", new THREE.InstancedBufferAttribute(instanceStrengthArray, 1));
    instancedMesh.geometry.setAttribute("instanceHover", new THREE.InstancedBufferAttribute(instanceHoverArray, 1));
    instancedMesh.geometry.setAttribute("instanceColorC", new THREE.InstancedBufferAttribute(instanceColorCArray, 3));
    instancedMesh.geometry.setAttribute("instanceColorD", new THREE.InstancedBufferAttribute(instanceColorDArray, 3));

    instancedBlobs.push(instancedMesh);
    scene.add(instancedMesh);
  });
});

/**
 * Lights
 */
const strongAmbientLight = new THREE.AmbientLight(0x74ae8f, 1.85);
scene.add(strongAmbientLight);

const spotlightOne = new THREE.DirectionalLight(0xfdfcdc, 0.075);
spotlightOne.position.set(15, 25, 4);
scene.add(spotlightOne);

const spotlightTwo = new THREE.DirectionalLight(0xfdfcdc, 0.057);
spotlightTwo.position.set(-15, 25, 4);
scene.add(spotlightTwo);

/**
 * Sizes
 */

const container = document.querySelector(".container");

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

function resizeCanvas() {
  sizes.width = container.clientWidth;
  sizes.height = container.clientHeight;
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
}

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.set(-0.1, 1, 18);
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1.0;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// Call initially and on resize
resizeCanvas();
window.addEventListener("resize", resizeCanvas);

// Post-processing
const composer = new EffectComposer(renderer);

// Standard Render Pass (for non-bloom objects)
const renderPass = new RenderPass(scene, camera);
composer.addPass(renderPass);

// Bloom Pass
const bloomPass = new UnrealBloomPass(
  new THREE.Vector2(sizes.width, sizes.height),
  0.35, // Strength of bloom
  0.15, // Radius of bloom
  0.15 // Threshold for bloom
);
composer.addPass(bloomPass);

/**
 * Interaction Logic
 */

const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

let scrollPercent = 0; // Initialize scroll percentage
let currentHoveredInstance = null; // Keep track of the currently hovered instance

// Object to track ongoing animations
const ongoingAnimations = {};

// Event listener for scroll to update scroll percentage
window.addEventListener("scroll", () => {
  const maxScrollY = document.documentElement.scrollHeight - window.innerHeight;
  scrollPercent = window.scrollY / maxScrollY;
});

// Detect touch devices
const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

// Function to get pointer position relative to the canvas
function getPointerPosition(event) {
  const rect = canvas.getBoundingClientRect();
  let x, y;
  if (event.touches && event.touches.length > 0) {
    x = event.touches[0].clientX;
    y = event.touches[0].clientY;
  } else {
    x = event.clientX;
    y = event.clientY;
  }
  pointer.x = ((x - rect.left) / rect.width) * 2 - 1;
  pointer.y = -((y - rect.top) / rect.height) * 2 + 1;
}

// Easing function
function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

// Event handlers
function onPointerMove(event) {
  getPointerPosition(event);
  handleHoverInteraction(); // Trigger hover interaction
}

// Remove event.preventDefault() from onTouchMove
// function onTouchMove(event) {
//   // No need for preventDefault, allows scrolling
//   getPointerPosition(event);
//   handleHoverInteraction(); // Trigger hover interaction
// }

function onTouchStart(event) {
  getPointerPosition(event);
  handleTouchInteraction();
}

function handleTouchInteraction() {
  raycaster.setFromCamera(pointer, camera);
  const intersects = raycaster.intersectObjects(instancedBlobs, true);

  if (intersects.length > 0 && intersects[0].instanceId !== undefined) {
    const instanceId = intersects[0].instanceId;
    const mesh = intersects[0].object;

    // Create THREE.Color objects from the color values
    const colorC = new THREE.Color(interactionEffect.colorC);
    const colorD = new THREE.Color(interactionEffect.colorD);

    // Animate the instance
    animateInstance(mesh, instanceId, interactionEffect.targetStrength, colorC, colorD, interactionEffect.duration);
  }
}

if (!isTouchDevice) {
  window.addEventListener("pointermove", onPointerMove);
} else {
  window.addEventListener("touchstart", onTouchStart, { passive: true });
  // Remove touchmove listener
  // window.addEventListener("touchmove", onTouchMove, { passive: true });
}

function handleHoverInteraction() {
  raycaster.setFromCamera(pointer, camera);
  const intersects = raycaster.intersectObjects(instancedBlobs, true);

  if (intersects.length > 0 && intersects[0].instanceId !== undefined) {
    const instanceId = intersects[0].instanceId;
    const mesh = intersects[0].object;

    if (!currentHoveredInstance || currentHoveredInstance.instanceId !== instanceId || currentHoveredInstance.mesh !== mesh) {
      // Animate back the previous instance, if different
      if (currentHoveredInstance && currentHoveredInstance.mesh !== mesh) {
        animateBackInstance(currentHoveredInstance.mesh, currentHoveredInstance.instanceId, interactionEffect.duration);
      }

      // Set the new hovered instance
      currentHoveredInstance = { mesh, instanceId };

      // Create THREE.Color objects from the color values
      const colorC = new THREE.Color(interactionEffect.colorC);
      const colorD = new THREE.Color(interactionEffect.colorD);

      // Animate the new instance
      animateInstance(mesh, instanceId, interactionEffect.targetStrength, colorC, colorD, interactionEffect.duration);
    }
  } else {
    // No intersection detected, animate back the previous instance
    if (currentHoveredInstance) {
      animateBackInstance(currentHoveredInstance.mesh, currentHoveredInstance.instanceId, interactionEffect.duration);
      currentHoveredInstance = null; // Reset hovered instance
    }
  }
}

// Revised animateInstance to handle continuation gracefully
function animateInstance(mesh, instanceId, targetStrength, colorC, colorD, duration) {
  const animationKey = `${mesh.uuid}-${instanceId}`;
  const startTime = performance.now();
  const strengthArray = mesh.geometry.attributes.instanceStrength.array;
  const colorCArray = mesh.geometry.attributes.instanceColorC.array;
  const colorDArray = mesh.geometry.attributes.instanceColorD.array;

  // Cancel any ongoing animation for this instance
  if (ongoingAnimations[animationKey]) {
    cancelAnimationFrame(ongoingAnimations[animationKey]);
    delete ongoingAnimations[animationKey];
  }

  // Initialize storage objects if necessary
  mesh.userData.originalStrength = mesh.userData.originalStrength || {};
  mesh.userData.originalColorC = mesh.userData.originalColorC || {};
  mesh.userData.originalColorD = mesh.userData.originalColorD || {};

  // Store the original values only if they haven't been stored yet
  if (mesh.userData.originalStrength[instanceId] === undefined) {
    mesh.userData.originalStrength[instanceId] = strengthArray[instanceId];
    mesh.userData.originalColorC[instanceId] = [colorCArray[instanceId * 3], colorCArray[instanceId * 3 + 1], colorCArray[instanceId * 3 + 2]];
    mesh.userData.originalColorD[instanceId] = [colorDArray[instanceId * 3], colorDArray[instanceId * 3 + 1], colorDArray[instanceId * 3 + 2]];
  }

  const originalStrength = strengthArray[instanceId];
  const originalColorC = [colorCArray[instanceId * 3], colorCArray[instanceId * 3 + 1], colorCArray[instanceId * 3 + 2]];
  const originalColorD = [colorDArray[instanceId * 3], colorDArray[instanceId * 3 + 1], colorDArray[instanceId * 3 + 2]];

  function animate() {
    const currentTime = performance.now();
    const elapsed = (currentTime - startTime) / 500;
    let progress = Math.min(elapsed / duration, 1);
    progress = easeInOutQuad(progress);

    // Animate strength from current state to targetStrength
    const newStrength = originalStrength + (targetStrength - originalStrength) * progress;
    strengthArray[instanceId] = newStrength;
    mesh.geometry.attributes.instanceStrength.needsUpdate = true;

    // Animate colors from current state to target colors
    const startColorC = new THREE.Color(...originalColorC);
    const startColorD = new THREE.Color(...originalColorD);

    const newColorC = startColorC.clone().lerp(colorC, progress);
    const newColorD = startColorD.clone().lerp(colorD, progress);

    colorCArray[instanceId * 3] = newColorC.r;
    colorCArray[instanceId * 3 + 1] = newColorC.g;
    colorCArray[instanceId * 3 + 2] = newColorC.b;

    colorDArray[instanceId * 3] = newColorD.r;
    colorDArray[instanceId * 3 + 1] = newColorD.g;
    colorDArray[instanceId * 3 + 2] = newColorD.b;

    mesh.geometry.attributes.instanceColorC.needsUpdate = true;
    mesh.geometry.attributes.instanceColorD.needsUpdate = true;

    // Set hover state
    const instanceHoverArray = mesh.geometry.attributes.instanceHover.array;
    instanceHoverArray[instanceId] = 1.0;
    mesh.geometry.attributes.instanceHover.needsUpdate = true;

    if (progress < 1) {
      ongoingAnimations[animationKey] = requestAnimationFrame(animate);
    } else {
      delete ongoingAnimations[animationKey];
    }
  }

  ongoingAnimations[animationKey] = requestAnimationFrame(animate);

  // Schedule the animation back to original state after a delay, if it's not hovered
  const resetDelay = 2 * 1000; // 2 seconds delay

  if (mesh.userData.resetTimeouts === undefined) {
    mesh.userData.resetTimeouts = {};
  }

  // Clear any existing reset timeout for the instance
  if (mesh.userData.resetTimeouts[instanceId]) {
    clearTimeout(mesh.userData.resetTimeouts[instanceId]);
  }

  mesh.userData.resetTimeouts[instanceId] = setTimeout(() => {
    if (currentHoveredInstance && currentHoveredInstance.instanceId === instanceId) {
      return; // Don't reset if it's currently hovered
    }
    animateBackInstance(mesh, instanceId, duration);
  }, resetDelay);
}

function animateBackInstance(mesh, instanceId, duration) {
  // Check if original values exist
  if (
    mesh.userData.originalStrength[instanceId] === undefined ||
    mesh.userData.originalColorC[instanceId] === undefined ||
    mesh.userData.originalColorD[instanceId] === undefined
  ) {
    // Original values not found, nothing to animate back to
    return;
  }

  const animationKey = `${mesh.uuid}-${instanceId}-back`;
  const startTime = performance.now();
  const strengthArray = mesh.geometry.attributes.instanceStrength.array;
  const colorCArray = mesh.geometry.attributes.instanceColorC.array;
  const colorDArray = mesh.geometry.attributes.instanceColorD.array;

  // Cancel any ongoing animation for this instance
  if (ongoingAnimations[animationKey]) {
    cancelAnimationFrame(ongoingAnimations[animationKey]);
    delete ongoingAnimations[animationKey];
  }

  const originalStrength = mesh.userData.originalStrength[instanceId];
  const originalColorC = mesh.userData.originalColorC[instanceId];
  const originalColorD = mesh.userData.originalColorD[instanceId];

  // Get current values as starting point
  const startStrength = strengthArray[instanceId];
  const startColorC = [colorCArray[instanceId * 3], colorCArray[instanceId * 3 + 1], colorCArray[instanceId * 3 + 2]];
  const startColorD = [colorDArray[instanceId * 3], colorDArray[instanceId * 3 + 1], colorDArray[instanceId * 3 + 2]];

  function animate() {
    const currentTime = performance.now();
    const elapsed = (currentTime - startTime) / 500;
    let progress = Math.min(elapsed / duration, 1);
    progress = easeInOutQuad(progress);

    // Animate strength back from current state to originalStrength
    const newStrength = startStrength + (originalStrength - startStrength) * progress;
    strengthArray[instanceId] = newStrength;
    mesh.geometry.attributes.instanceStrength.needsUpdate = true;

    // Animate colors back from current state to original colors
    const startColorCObj = new THREE.Color(...startColorC);
    const startColorDObj = new THREE.Color(...startColorD);

    const targetColorC = new THREE.Color(...originalColorC);
    const targetColorD = new THREE.Color(...originalColorD);

    const newColorC = startColorCObj.clone().lerp(targetColorC, progress);
    const newColorD = startColorDObj.clone().lerp(targetColorD, progress);

    colorCArray[instanceId * 3] = newColorC.r;
    colorCArray[instanceId * 3 + 1] = newColorC.g;
    colorCArray[instanceId * 3 + 2] = newColorC.b;

    colorDArray[instanceId * 3] = newColorD.r;
    colorDArray[instanceId * 3 + 1] = newColorD.g;
    colorDArray[instanceId * 3 + 2] = newColorD.b;

    mesh.geometry.attributes.instanceColorC.needsUpdate = true;
    mesh.geometry.attributes.instanceColorD.needsUpdate = true;

    if (progress < 1) {
      ongoingAnimations[animationKey] = requestAnimationFrame(animate);
    } else {
      // Clean up
      delete ongoingAnimations[animationKey];

      // Now reset hover state
      const instanceHoverArray = mesh.geometry.attributes.instanceHover.array;
      instanceHoverArray[instanceId] = 0.0;
      mesh.geometry.attributes.instanceHover.needsUpdate = true;

      // Remove stored original values
      delete mesh.userData.originalStrength[instanceId];
      delete mesh.userData.originalColorC[instanceId];
      delete mesh.userData.originalColorD[instanceId];
    }
  }

  ongoingAnimations[animationKey] = requestAnimationFrame(animate);
}

/**
 * Animation Loop
 */
const clock = new THREE.Clock();

function tick() {
  const elapsedTime = clock.getElapsedTime();

  // Stats.js
  stats.begin();

  // Update uniforms
  pepeUniforms.uTime.value = elapsedTime;
  blobUniforms.uTime.value = elapsedTime;

  // Update camera position based on scroll
  camera.position.y = -scrollPercent * 15;

  // Animate pepe object
  if (pepe) {
    pepe.rotation.z = Math.sin(elapsedTime * 0.5) * 0.15;
  }

  // Render
  composer.render();

  // End Stats.js
  stats.end();

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
}
